import { v4 as uuidv4 } from "uuid";
import { H1, P, Input, Button, Icon, H3, ProgressIndicator } from "@dnb/eufemia";
import { chatbot as Chatbot } from '@dnb/eufemia/icons'
import { BASE_API_URL } from './config';
// import { useChatInteract, useChatMessages } from "@chainlit/react-client";
import { useState, useRef, useEffect } from "react";

const CHAT_HISTORY_LENGTH = 10;

export function Playground() {
  const [inputValue, setInputValue] = useState("");
  const [allMessages, setAllMessages] = useState<ChatMessage[]>([])
  const [chatScore, setChatScore] = useState<number>(5)
  const messageEndRef =  useRef<HTMLDivElement>(null);
  const [scroll, setScroll] = useState([""])
  const [isLoading, setIsLoading] = useState(false);
  const [activeWorkflow, setActiveWorkflow] = useState(false)
  const [workflow, setWorkflow] = useState<Workflow>()
  const [workflowStepIndex, setworkflowStepIndex] = useState<number>()

  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [scroll]);

  useEffect(() => {
    if (activeWorkflow)
      console.log("Workflow active")
    else {
      console.log("Workflow not active")
      setWorkflow(undefined)
      setworkflowStepIndex(undefined)
    }
  }, [activeWorkflow])

  useEffect(() => {
    console.log("Workflow changed")
  }, [workflow])

  useEffect(() =>  {

    if (workflow !== undefined && workflowStepIndex !== undefined ) {
      
      console.log("workflowStepIndex: " + workflowStepIndex + ", workflow.steps.length: " + workflow.steps.length)
      if (workflowStepIndex < workflow.steps.length) {
        // let updatedArray = [
        //   ...allMessages,
        //   {
        //     id: uuidv4(),
        //     user: "Workflow",
        //     text: workflow.steps[workflowStepIndex].desc,
        //     createdAt: getCurrentTime(),
        //   }
        // ]
        // setAllMessages(
        //   updatedArray
        // );

        setScroll((prev) => [...prev, `New message ${prev.length + 1}`])
      }
      else {
        console.log("Should do some action???")
        // TODO trigger action,
        // let updatedArray = [
        //   ...allMessages,
        //   {
        //     id: uuidv4(),
        //     user: "Workflow",
        //     text: "ACTION TRIGGERED, Result will be coming here...",
        //     createdAt: "",
        //     prompt: "",
        //     answeredAt: ""
        //   }
        // ]
        // setAllMessages(
        //   updatedArray
        // );

        setScroll((prev) => [...prev, `New message ${prev.length + 1}`])
        setActiveWorkflow(false)
      }
    }
  }, [workflowStepIndex])

  const cancel_workflow = () => {
    setActiveWorkflow(false)
  }

  const analyzeChat = (messagesDmp: ChatMessage[]) => {
    fetch(`${BASE_API_URL}/chatAnalysis`, {

      method: "POST",
      body: JSON.stringify({
        chat: messagesDmp
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
    .then((response) => response.json())
    .then((data: ChatAnalysisDTO) => {
       console.log(data);
       if (data.chatAnalysis.length === 1) {
        try {
          let chatScoreNum = Number.parseInt(data.chatAnalysis)
          setChatScore(chatScoreNum)
        } catch (e) {
          setChatScore(6)
        }
      }
    })
    .finally(() => {

    })
    .catch((err) => {
       console.log(err.message);
    });
  }

  const getCurrentTime = () => {
    var today = new Date();
    var curTime = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    return curTime;
  }

  const sendChatMessageToAPI = async (message: string, rag: boolean = false, fullChatHistory: ChatHistoryItem[]) => {
    setIsLoading(true)
    let api = `${BASE_API_URL}/chat/1234`;
    if (rag) {
      api = `${BASE_API_URL}/ragChat/1234`;
    }

    const tmp0 = allMessages;
    console.log("allMessages.length in sendChatMessageToAPI 0: " + tmp0.length);
    const msgId = uuidv4()
    setAllMessages(
      [
        ...tmp0,
        {
          id: msgId,
          user: "user",
          text: message,
          createdAt: getCurrentTime()
        }
      ]
    );


    let response = await fetch(api, {
      method: "POST",
      body: JSON.stringify({
        prompt: message,
        chatHistory: fullChatHistory
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
    let data = await response.json();
    if (data.llmResponse === "-1") {

      setIsLoading(false)
      console.log(data);
      setActiveWorkflow(true)
      loadWorkflow(data.id)

    } else {
      const tmp = allMessages;
      console.log("allResponses.length in sendChatMessageToAPI 1: " + tmp.length);
      setAllMessages(
        [
          ...tmp,
          {
            id: msgId,
            user: "user",
            text: data.prompt,
            createdAt: data.createdAt,
          },
          {
            id: data.id,
            user: "assistant",
            text: data.llmResponse,
            createdAt: data.answeredAt,
          }
        ]
      );
      setScroll((prev) => [...prev, `New message ${prev.length + 1}`])
      setIsLoading(false)

      // analyzeChat(updatedArray)
    }

      //  .then((response) => response.json())
      //  .then((data: ChatMessageDTO) => {
        
      //  })
      //  .catch((err) => {
      //     console.log(err.message);
      //  });
  }

  const loadWorkflow = (id: string) => {
    setIsLoading(true)
    let api = `${BASE_API_URL}/workflow/${id}`;
    fetch(api, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data: Workflow) => {
        setIsLoading(false)
        setWorkflow(data)
        setworkflowStepIndex(0)
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const storeValueFromWorkflow = () => {

    const content = inputValue.trim();
    console.log("INput value: " + content)

    if (workflowStepIndex !== undefined) {

      if (workflow !== undefined) {
        if (workflow.steps.length >= workflowStepIndex) {
          // let updatedArray = [
          //   ...allMessages,
          //   {
          //     id: uuidv4(),
          //     user: "user",
          //     text: "content",
          //     createdAt: ""
          //   }
          // ]
          // setAllMessages(
          //   updatedArray
          // );

          setScroll((prev) => [...prev, `New message ${prev.length + 1}`])
        }
        else {
          // Last step: ACTION
          console.log("DO ACTION. End of workflow")
        }

        setworkflowStepIndex(workflowStepIndex + 1)
      }
      else {
        console.log("workflow undefined")
      }
    }
    else {
      console.log("workflow step index undefined")
    }

    setInputValue("");
  }


  const handleRagSendMessage = () => {
    const content = inputValue.trim();
    if (content) {
      let fullChatHistory: ChatHistoryItem[] =  []
      allMessages.forEach(msg => {
        fullChatHistory.push({"role": msg.user, "content": msg.text})
      });

      // if (allMessages.length > 2 * CHAT_HISTORY_LENGTH) {
      while (fullChatHistory.length > 2 * CHAT_HISTORY_LENGTH) {
        fullChatHistory.shift()
      }
      // }
      const message = {
        name: "user",
        type: "user_message" as const,
        output: content,
      };
      sendChatMessageToAPI(message.output, true, fullChatHistory)
      setInputValue("");
    }
  };

  const renderMessage = (message: ChatMessage) => {

    return (
      <div key={message.id} >
        {
          message.user === "user" ?

          <div className="grid place-content-end flex items-start space-x-2 m-auto pl-1">
            <div>
                <small className="text-xs text-dnb-black-55">{message.createdAt.substring(0,5)}</small>
                <div>
                  <div className="inline-block px-4 py-2 bg-white rounded border border-1 max-w-96 md:max-w-prose lg:max-w-screen-lg">
                    <P>{message.text}</P>
                  </div>
                </div>
            </div>
          </div>
          :

            <div className="flex pr-10 items-start space-x-2 m-auto pt-2">
              <div className="flex-1">
                <small className="text-xs text-dnb-black-55">
                  {message.createdAt.substring(0,5)}
                </small>
                <P><Icon icon={Chatbot} title="Robot Icon." /> {message.text}</P>
              </div>
              <div ref={messageEndRef}></div>
            </div>
        }
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-subtle-grey dark:bg-dnb-oceangreen flex flex-col">
      <div className="text-center p-10 text-dnb-oceangreen">
        <H1>
          {
            chatScore > 4?
              <Icon icon={Chatbot} color={"text-dnb-oceangreen"} title="Robot Icon." />
            :

              chatScore > 2?
                <Icon icon={Chatbot} color={"orange"}  title="Robot Icon." />
              :
                <Icon icon={Chatbot} color={"red"}  title="Robot Icon." />
          }
      
        </H1>
        <div className="pt-5 text-dnb-summergreen">
        <H3>NTL - RAG-bot</H3>
        { activeWorkflow ?
          <div>
            <p>Workflow mode</p>
            <Button text="cancel" on_click={cancel_workflow} />
          </div>
        :
          <div/>
        }
        </div>
      </div>
      <div className="flex-1 overflow-auto p-6">
        <div className="space-y-4">
          {
            allMessages.map((message) => renderMessage(message))
          }
        </div>
        {
          isLoading?
            <ProgressIndicator className="text-center"/>
            :
            <div/>
        }
      </div>
      <div className="sticky bottom-0 border-t p-4 bg-white dark:bg-gray-800">
        <div className="flex items-center space-x-2">
          <Input
            size="medium"
            placeholder="Skriv her..."
            stretch={true}
            value={inputValue}
            on_change={({ value }) => {
              setInputValue(value)
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                if (activeWorkflow)
                  storeValueFromWorkflow();
                else  
                  handleRagSendMessage();
              }
            }}
          />
          { activeWorkflow ?
            <Button
            text="Send"
            on_click={storeValueFromWorkflow}
            />
          :
            <Button
              text="Send"
              on_click={handleRagSendMessage}
            />
          }
        </div>
      </div>
    </div>
  );
}